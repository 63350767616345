@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #ffffff; /* Light background */
  color: #334774; /* Primary text color */
}

#bgneed2 {
  background-color: #ffffff;
  background-image: linear-gradient(
    145deg,
    #ffffff 0%,
    #f0f4ff 50%,
    #d9e2ff 100%
  );
}

#textstyle {
  background: -webkit-linear-gradient(#334774, #5a6e99);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.slide-in {
  animation: slideIn 0.5s ease-out;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  color: #334774; /* Code color */
}

.faq {
  width: 100%;
  padding: 130px 0;
  color: #334774;
  background-color: #f9f9ff; /* Light FAQ section */
}

.faq-con {
  max-width: 1140px;
  margin: auto;
}

.faq-left h1 {
  font-size: 50px;
  line-height: 70px;
  color: #334774; /* Heading color */
  font-weight: bold;
  margin-bottom: 40px;
}

.faq-left p {
  font-size: 20px;
  color: #5a6e99; /* Subtitle color */
  line-height: 36px;
}

.faq-right .faq-box {
  padding: 45px 0;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #d0d7e7; /* Light border */
}

.faq-right .faq-box:last-child {
  border-bottom: 1px solid #d0d7e7;
}

.uni {
  --tw-bg-opacity: 1;
  color: #334774;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #f0f4ff; /* Light track background */
}

::-webkit-scrollbar-thumb {
  background: #334774; /* Thumb color */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #5a6e99;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #334774 #f0f4ff;
}
